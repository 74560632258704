/* Common */
$PC-font: -apple-system, BlinkMacSystemFont, helvetica, Apple SD Gothic Neo, '나눔고딕', NanumGothic, sans-serif;

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
select {
  margin: 0;
  padding: 0;
}

body,
input,
textarea,
select,
button,
table {
  font-family: $PC-font;
  font-size: 14px;
  line-height: normal;
  letter-spacing: -0.5px;
}

body {
  position: relative;
  background-color: #fff;
  -webkit-text-size-adjust: none;
}

img,
fieldset {
  border: 0;
}

ul,
ol {
  list-style: none;
}

em,
address {
  font-style: normal;
}

a {
  text-decoration: none;
}

table {
  border-collapse: collapse;
}

mark {
  background: transparent;
}

html,
head,
body,
#root {
  height: 100%;
}

svg,
img {
  vertical-align: top;
}

#u_skip,
.blind {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.input-hidden {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
