.flag_ul {
  // max-width: 1500px;
}

.flag_li {
  display: inline-block;
  margin: 4px;
}

.flag_dl {
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
  padding: 7px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}
